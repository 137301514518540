<template>
  <div class="topline">
    <p v-if="$route.name === 'Tech'" @click="$router.push('/')">Home</p>
    <p v-else @click="$router.push('/tech')">Technologies</p>
    <p v-if="$route.name === 'Portfolio'" @click="$router.push('/')">Home</p>
    <p v-else @click="$router.push('/portfolio')">Portfolio</p>
    <p v-if="$route.name === 'About'" @click="$router.push('/')">Home</p>
    <p v-else @click="$router.push('/about')">About me</p>
  </div>
</template>

<script>
export default {
  name: "topline"
}
</script>

<style scoped>
.topline{
  display: flex;
  background-color: #131313;
  justify-content: space-around;
  width: 100%;
  height: 8vh;
  color: white;
  border-bottom: 1px solid #cbcbcb;
}
.topline p {
  text-transform: uppercase;
  display: flex;
  height: 100%;
  align-items: center;
  transition: .5s;
}

@media (min-width: 1200px) {
  .topline p:hover{
    cursor: pointer;
    transition: .5s;
    color: #24bb24;
  }
}
</style>