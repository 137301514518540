<template>
  <div class="bottomline">
    <p class="mdi mdi-instagram" @click="openlink('https://instagram.com/elisha.profile')"></p>
    <p class="mdi mdi-facebook" @click="openlink('https://web.facebook.com/profile.php?id=100032083426185')"></p>
    <p class="mdi mdi-vk" @click="openlink('https://vk.com/ikey4')">VK</p>
    <p class="mdi mdi-linkedin" @click="openlink('https://www.linkedin.com/in/elisha-kravchuk-aa41251bb/')"></p>
    <p class="mdi mdi-gmail" @click="copygmail" :title="gmailtext"></p>
    <div class="gmail_compied_message">
      {{alerttext}}
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomline",

  data(){
    return{
      alerttext: 'Copied'
    }
  },
components: [

  ],
  methods:{
    openlink(url){
      window.open(url)
    },
    copygmail(){
      navigator.clipboard.writeText('elisha.kravchuk@gmail.com')
      .then(() => {
        document.getElementsByClassName('gmail_compied_message')[0].setAttribute('style', 'visibility: visible; opacity: 1')
         setTimeout(function(){ document.getElementsByClassName('gmail_compied_message')[0].setAttribute('style', 'visibility: hidden; opacity: 0')}, 2000);
      })
      .catch(err => {
        this.alerttext = 'Error copy; elisha.kravchuk@gmail.com'
        document.getElementsByClassName('alert')[0].setAttribute('style', 'top: 30vh')
        document.getElementsByClassName('gmail_compied_message')[0].setAttribute('style', 'background-color: red; visibility: visible; opacity: 1')
        setTimeout(function(){ document.getElementsByClassName('alert')[0].setAttribute('style', 'visibility: hidden; opacity: 0')}, 7000);
        console.log('Something went wrong', err);
      });
    }
  }

}
</script>

<style scoped>
.bottomline{
  height: 12vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-size: 1.8rem;
  background-color: #131313;
}
.bottomline p{
    transition: .3s;
}

@media (min-width: 1200px) {
  .bottomline p:hover {
    color: #24bb24;
    transition: .3s;
    cursor: pointer;
  }
}

.gmail_compied_message{
  position: absolute;
  display: flex;
  top: 10vh;
  right: 20px;
  background-color: rgb(50, 184, 50);
  padding: 20px 50px;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 1px #24bb24;
  transition: .65s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(2%);
}
</style>