<template>
  <div id="app">
    <topline/>
    <transition name="slide-fade"><router-view></router-view></transition>
    <bottomline/>
  </div>
</template>
<script>
import topline from "@/components/topline";
import bottomline from "@/components/bottomline";
export default {
  name: 'App',

  components: {
    topline,
    bottomline

},

  data: () => ({
    //
  }),
  created() {
    this.$store.commit('changescreentype', document.getElementById('app').clientWidth)
    console.log(document.getElementById('app').clientWidth)
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
::-webkit-scrollbar{
  width: 0px;
}
*{
  padding:0;
  margin: 0;
}

.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all 20ms cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  position: absolute;
  transform-style: flat;
  transform-origin: center;
  transform: rotateX(180deg);
  opacity: 0;
}
</style>
