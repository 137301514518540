<template>
  <div class="home">

    <index />
  </div>
</template>

<script>
import index from "@/components/index";

export default {
  name: 'Home',
  components: {
    index
  }
}
</script>
