import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        screentype: ''
    },
    mutations:{
        changescreentype(state, type){
            state.screentype = type
        }
    }
})

export default store