<template>
  <div>

      <div class="mainblock">
        <p class="myname">Elisey Kravchuk</p>
        <p class="fsd">Full-stack developer</p>
      </div>


<!--      <div class="alert">-->
<!--        <p class="alerttext">{{alerttext}}</p>-->
<!--      </div>-->
  </div>
</template>

<script>
export default {
name: "index",
  data(){
    return{
      alerttext: 'Copied'
    }
  },
components: [

  ],
  methods:{
    openlink(url){
      window.open(url)
    },
    copygmail(){
      navigator.clipboard.writeText('elisha.kravchuk@gmail.com')
      .then(() => {
        document.getElementsByClassName('alert')[0].setAttribute('style', 'top: 30vh')
         setTimeout(function(){ document.getElementsByClassName('alert')[0].setAttribute('style', 'top: -30vh')}, 1000);
      })
      .catch(err => {
        this.alerttext = 'Error copy; elisha.kravchuk@gmail.com'
        document.getElementsByClassName('alert')[0].setAttribute('style', 'top: 30vh')
        document.getElementsByClassName('alerttext')[0].setAttribute('style', 'background-color: red')
        setTimeout(function(){ document.getElementsByClassName('alert')[0].setAttribute('style', 'top: -30vh')}, 5000);
        console.log('Something went wrong', err);
      });
    }
  }
}
</script>

<style scoped >
.mainblock{
  display: flex;
  background-color: #131313;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 80vh;
  flex-direction: column;

}
.myname{
  display: flex;
  position: relative;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size: 2em;
  letter-spacing: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, white, green, white);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 5s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}

.fsd{
  font-size: 1.2rem;
  padding-top: 30px;
  animation: blicking 1s linear infinite;
}
@keyframes blicking {
  0%{
    color: #131313;
  }
  50%{
    color: white;
    text-shadow: white;
  }
  100%{
    color: #131313;
  }
}

.alert{
  position: absolute;
  top: -30vh;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: .5s;
}
.alert p{
  display: flex;
  padding: 30px;
  background-color: #4e954e;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #1d1d1d;
  border-radius: 20px;
  width: 10%;
  color: white;
  font-size: 1.5rem;
}
</style>